// MainPageNavbar.js
import React from 'react';
import { MiddlePillNavbar } from './MiddlePillNavBar'; // Updated import syntax
import { NavBarDropdown } from './NavBarDropdown'; // Corrected capitalization and import syntax
import './MainPageNavbar.css';

export function MainPageNavbar({ activeTab, setActiveTab }) {

  return (
    <div className="main-page-navbar">
      <div className="navbar-container">
        <div className="middle-navbar">
          <MiddlePillNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div className="right-navbar">
          <NavBarDropdown />
        </div>
      </div>
    </div>
  );
}
