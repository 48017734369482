import React from 'react';

function ContactSection() {
  return (
        <a href="mailto:aidancarling123@gmail.com?subject=Hello%20There">
          reach out
        </a>
  );
}

export { ContactSection };
