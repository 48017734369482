import React from 'react';
import './AboutContent.css';
import { PhotoCollage } from './PhotoCollage'; // Import the new component
import { ContactSection } from './ContactEmail'

export function AboutContent() {
    return (
        <div className="About-content">
          <PhotoCollage />
          <h1 className="about-heading">Nice to see you again,</h1>
            <p className="about-text">
              I am currently a Data Analyst at BNY, working within the Growth Ventures department where BNY's Data and Analytics team sit.
              I work on development projects developing data management and analysis solutions for buy-side clients.
            </p>
            <p className="about-text">
              The projects I work on are a mix of data management, data analysis, and data visualisation. I work with a range of technologies including SQL, Python, and PowerBI.
              The aim of the projects is to improve efficiency by automating manual processes, create new insights from data, and provide a better user experience for front office users.
            </p>
            <p className="about-text">
              Previously, I worked at Century Tech as part of the Science Content Team. 
              I worked on developing the science curriculum for the platform, creating interactive content for students to learn from.
            </p>
            <p className="about-text">
              I have also worked at a Start Up called Agora as a Software Developer, working on developing the front end of the platform.
              Here I worked with React, Flask, and Python. I also enjoy teaching and sharing and so I have spent time as a physics tutor, teaching students in A-level.
            </p>
            <p className="about-text">
            Outside of work, I enjoy exploring for food that I have not tried before. This includes researching new restaurants or planning holidays around food.
            I think a lot about problem solving as its the best way to grasp my attention. I do this with programming projects and learning about new technologies.
            I also like getting my hands dirty and building things, feeling accomplished with the results. I enjoy building mechanical keyboards or upgrading old tech pieces.
            </p>
            <p className="about-text">
              If you feel like a chat, please don't hesitate to <ContactSection />.
            </p>
            
        </div>
    )
}