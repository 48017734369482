// NavBarDropdown.js
import React, { useState } from 'react';
import { Dropdown, ButtonGroup, Fade } from 'react-bootstrap';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import './NavBarDropdown.css';

export function NavBarDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(prevState => !prevState);

  return (
    <Dropdown as={ButtonGroup} drop="down" align="end" show={isOpen} className="navbar-dropdown">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-custom"
        onClick={toggleDropdown}
        className="toggle-button"
      >
        <div className={`hamburger-icon ${isOpen ? 'open' : ''}`}>
          <div className="line top"></div>
          <div className="line bottom"></div>
        </div>
      </Dropdown.Toggle>

      <Fade in={isOpen} mountOnEnter unmountOnExit>
        <Dropdown.Menu align="end" className="dropdown-menu-custom">
          <Dropdown.Item href="/" className="dropdown-item-custom">
            <AiOutlineHome className="icon" />
            <span className="text">Home</span>
          </Dropdown.Item>
          <Dropdown.Item href="about" className="dropdown-item-custom">
            <AiOutlineUser className="icon" />
            <span className="text">About</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Fade>
    </Dropdown>
  );
}
