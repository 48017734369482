import React from 'react';
import '../App.css';
import { WorkIntroduction } from './WorkIntroduction';
import { ProjectCard } from './ProjectCard';

// Import images
import BNY_Data_Vault from '../Images/BNY_Data_Vault.jpeg';
import Century_Tech_Ipad from '../Images/century_ipad_new.webp';
import agora_stream from '../Images/agora_stream_logo.png';
import tutor_stock from '../Images/tutoring_stock.jpg';
import { FloatingBackground } from './FloatingBackground';


export function WorkContent() {
    return (
        <div className="work-content">
          <FloatingBackground />
          <WorkIntroduction />
          <div className="card-container">
            <ProjectCard
                title="Data & Analytics Associate"
                subtitle="BNY"
                year="2021"
                description="Developing data mangeament solutions for buy side clients. Aiming to improve automation, cost efficiencies and insights into the data."
                imageSrc={BNY_Data_Vault} // Replace with actual image URL
                link="https://www.bny.com/corporate/global/en/solutions/data-and-analytics.html" // Replace with the actual link
                buttonText="Read More"
              />
          </div>
          <div className="card-container">
            <ProjectCard
                title="Science Content Team"
                subtitle="Centurt Tech"
                year="2019"
                description="Developing the science curriculum for the platform, creating interactive content for students to learn from. Being inspired surronded by the Scale up culture."
                imageSrc={Century_Tech_Ipad} // Replace with actual image URL
                link="https://www.century.tech/" // Replace with the actual link
                buttonText="Read More"
              />
          </div>
          <div className="card-container">
            <ProjectCard
                title="Software Developer"
                subtitle="Agora.stream (Now part of Cassyni)"
                year="2021"
                description="Full stack developer of the research seminar platform. Working with React, Flask, and Python. Being part of a start up and seeing the growth of the company."
                imageSrc={ agora_stream } // Replace with actual image URL
                link="https://cassyni.com/" // Replace with the actual link
                buttonText="Read More"
              />
          </div>
          <div className="card-container">
            <ProjectCard
                title="Physics Tutor"
                subtitle="A-level"
                year="2021"
                description="Teaching students in A-level physics. Enjoying the process of teaching, sharing knowledge and the impact on individuals."
                imageSrc={ tutor_stock } // Replace with actual image URL
                link="https://www.ocr.org.uk/qualifications/as-and-a-level/physics-a-h156-h556-from-2015/" // Replace with the actual link
                buttonText="Curriculum"
              />
          </div>
        </div>
    )
}