import React from 'react';
import { FaXTwitter, FaLinkedin, FaGithub } from 'react-icons/fa6'; // Importing icons from react-icons
import './Footer.css';

export function Footer() {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://x.com/CarlingAidan" target="_blank" rel="noopener noreferrer">
          <FaXTwitter className="icon" />
        </a>
        <a href="https://linkedin.com/in/aidan-carling" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="icon" />
        </a>
        <a href="https://github.com/AidanCarling" target="_blank" rel="noopener noreferrer">
          <FaGithub className="icon" />
        </a>
      </div>
      <p className="copyright">
        Copyright © {new Date().getFullYear()} Aidan Carling. All rights reserved.
      </p>
    </footer>
  );
}
