import React from 'react';
import './ProjectCard.css';

export function ProjectCard({ title, subtitle, year, description, imageSrc, link, buttonText }) {
  const handleClick = () => {
    window.location.href = link; // Redirects to link when the card is clicked
  };

  return (
    <div className="project-card" onClick={handleClick}>
      <div className="card-content">
        <div className="card-text">
          <h3 className="card-title">{title}</h3>
          <p className="card-subtitle">{subtitle} • {year}</p>
          <p className="card-description">{description}</p>
          <a href={link} target="_blank" rel="noopener noreferrer" className="card-button">
            {buttonText}
          </a>
        </div>
        <div className="card-image">
          <img src={imageSrc} alt={`${title} screenshot`} />
        </div>
      </div>
    </div>
  );
}
