// MiddlePillNavbar.js
import React from 'react';
import './MiddlePillNavbar.css';

function MiddlePillNavbar({ activeTab, setActiveTab }) {

  return (
    <div className="middle-pill-navbar-container">
      <div className={`highlight ${activeTab === 'hobbies' ? 'highlight-hobbies' : ''}`} />
      <button
        className={`pill-button ${activeTab === 'work' ? 'active' : ''}`}
        onClick={() => setActiveTab('work')}
      >
        Work
      </button>
      <button
        className={`pill-button ${activeTab === 'hobbies' ? 'active' : ''}`}
        onClick={() => setActiveTab('hobbies')}
      >
        hobbies
      </button>
    </div>
  );
}

export { MiddlePillNavbar };