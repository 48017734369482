import React from 'react';
import './FloatingBackground.css';

export function FloatingBackground() {
  return (
    <div className="floating-background">
      <div className="floating-object object1"></div>
      <div className="floating-object object2"></div>
      <div className="floating-object object3"></div>
      <div className="floating-object object4"></div>
      <div className="floating-object object5"></div>
    </div>
  );
}
