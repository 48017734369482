import React from 'react';
import { Image } from 'react-bootstrap';
import './PhotoCollage.css';

//import images
import image1 from '../Images/Profile_Picture1.jpeg';
import image2 from '../Images/Profile_Picture2.jpeg';
import image3 from '../Images/Profile_Picture3.jpeg';

export function PhotoCollage() {
  return (
    <div className="photo-collage">
      <Image
        src={image1} // Replace with actual image URL
        className="collage-image collage-image-1"
        rounded
      />
      <Image
        src={image2} // Replace with actual image URL
        className="collage-image collage-image-2"
        rounded
      />
      <Image
        src={image3} // Replace with actual image URL
        className="collage-image collage-image-3"
        rounded
      />
    </div>
  );
}
