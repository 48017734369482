import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import location icon
import './Introduction.css';

export function WorkIntroduction() {
  return (
    <div className="introduction">
      <h1 className="intro-heading">hi, i'm aidan</h1>
      <p className="location">
        <FaMapMarkerAlt className="location-icon" /> london, uk
      </p>
      <p className="intro-text">
        currently working at <a href="https://www.bny.com/corporate/global/en/solutions/data-and-analytics.html" target="_blank" rel="noopener noreferrer">bny</a>,
        within growth ventures developing data mangement & analysis solution for buy side clients.
      </p>
    </div>
  );
}
