import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import location icon
import './Introduction.css';

export function HobbiesIntroduction() {
  return (
    <div className="introduction">
      <h1 className="intro-heading">hi, i'm aidan</h1>
      <p className="location">
        <FaMapMarkerAlt className="location-icon" /> london, uk
      </p>
      <p className="intro-text">
        i enjoy all things programming, building and reviving old tech, and learning new things. 
        <br />anything thats a challenge, i'm in!
      </p>
    </div>
  );
}
