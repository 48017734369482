import React from 'react';
import '../App.css';
import { HobbiesIntroduction } from './HobbiesIntroduction';
import { ProjectCard } from './ProjectCard';
import { FloatingBackground } from './FloatingBackground';

// Import images
import printing from '../Images/3d_printing_stock.webp';
import mechanical_keyboard from '../Images/Mechanical_Keyboard_tomo.jpeg';
import python_library from '../Images/python_library_logo.svg';
import meal_prep_logo2 from '../Images/Weekly_Meal_Prep_logo.webp';
import financial_logo from '../Images/financial_system_logo.webp';


export function HobbiesContent() {
    return (
        
        <div className="hobbies-content">
          <FloatingBackground />
          <HobbiesIntroduction />

          <div className="card-container">
            <ProjectCard
                title="Financial Data Analysis System"
                subtitle="Creating a personal finance system"
                year="2024"
                description="Developing a system to manage and analyse my personal finances.
                  I am aiming to continue developing and improving this system as the alterior
                  motive is to learn more about finance and technology (software and hardware),
                  which will translate in to my professional career."
                imageSrc={ financial_logo }
                link="/financialsystem"
                buttonText="Read More"
              />
          </div>

          <div className="card-container">
            <ProjectCard
                title="3D Printing"
                subtitle="New and excited about the possibilites"
                year="2024"
                description="New to the world of 3D printing and excited about the possibilities.
                  I am currently learning about the different types of printers, materials and software to create some cool projects.
                  I am aiming to create things to optimise my life and make things easier, hopefully while staying aesthetic."
                imageSrc={ printing }
                link="/3dprinting"
                buttonText="Read More"
              />
          </div>

          <div className="card-container">
            <ProjectCard
                  title="Weekly Meal Prep"
                  subtitle="Healthy, tasty and efficient"
                  year="2024"
                  description="I am a big fan of meal prepping. I find it a great way to save time, eat healthier and save money.
                    However I find myself getting bored of the same meals or having left over ingredients.
                    This project was a way try and optimise my weekly food shopping to get a variety of meals while reducing waste and costs."
                  imageSrc={ meal_prep_logo2 }
                  link="/mealprep"
                  buttonText="Read More"
                />
            </div>

          <div className="card-container">
            <ProjectCard
                title="Mechanical Keyboards"
                subtitle="building and designing keyboards"
                year="2020"
                description="Fascinated by the world of mechanical keyboards. Building, customising and learning about the different variations and how to optimise.
                  one day I will reach the end game."
                imageSrc={ mechanical_keyboard }
                link="/keyboards"
                buttonText="Read More"
              />
            </div>

            <div className="card-container">
            <ProjectCard
                title="Spanish Conjugator"
                subtitle="python Library Package"
                year="2020"
                description="Colaborating with my brother to create a python library package that conjugates spanish verbs.
                  The aim is to create a package that can be used by others to help them learn and understand the conjugation of verbs
                  along with integrating into translation applications."
                imageSrc={ python_library }
                link="https://pypi.org/project/spanishconjugator/"
                buttonText="Read More"
              />
            </div>
        </div>
    )
}