import './App.css';

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { MainPageNavbar } from './Components/MainPageNavbar';
import { Footer } from './Components/Footer';
import { WorkContent } from './Components/WorkContent';
import { HobbiesContent } from './Components/HobbiesContent';

import { AboutPageNavbar } from './Components/AboutPageNavbar';
import { AboutContent } from './Components/AboutContent';
import { Placeholder } from './Components/Placeholder';

function App() {
  const [activeTab, setActiveTab] = useState('work'); // Initial state is 'work'

  

  return (
    <Router>
      <div className="App">
        <Routes>
        {/* Define routes for each page */}
          <Route path="/" element={ 
            <>
              <MainPageNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
              {activeTab === 'work' ? (
                <WorkContent />
                ) : (
                <HobbiesContent />
                )
              }
            </>
          } />
          <Route path="/about" element={
            <div className="about">
              <AboutPageNavbar />
              <AboutContent />
            </div>
          } />
          <Route path="/financialsystem" element={
            <div className="Placeholder">
              <AboutPageNavbar />
              <Placeholder />
            </div>
            } />
          <Route path="/3dprinting" element={
            <div className="Placeholder">
              <AboutPageNavbar />
              <Placeholder />
            </div>
            } />
          <Route path="/mealprep" element={
            <div className="Placeholder">
              <AboutPageNavbar />
              <Placeholder />
            </div>
            } />
          <Route path="/keyboards" element={
            <div className="Placeholder">
              <AboutPageNavbar />
              <Placeholder />
            </div>
            } />
          <Route path="*" element={<div className="error">404 Not Found</div>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
