import React from 'react';
import './Placeholder.css';
import { FloatingBackground } from './FloatingBackground';

export function Placeholder() {
  return (
    
    <div className="placeholder-page">
      <h1 className="placeholder-heading">Coming Soon</h1>
      <p className="placeholder-subheading">
        This page is currently under construction. Please check back later!
      </p>
      <FloatingBackground />
    </div>
  );
}
